import classNames from 'classnames';
import Image from 'components/Image';
import Link from 'components/Link';
import { sendLinkClickEvent } from 'utils/metrics';
import styles from './GridCarouselItem.module.scss';

export function GridCarouselItem({ content, id }) {
  const {
    cta_color: ctaColor,
    cta_size: ctaSize,
    cta_type: ctaType,
    cta_theme: ctaTheme,
    disclaimer_alignment: disclaimerAlignment,
    disclaimer_color: disclaimerColor,
    disclaimer_overlay_background_color: disclaimerOverlayBackgroundColor,
    disclaimer_text: disclaimerText,
    header_color: headerColor,
    header_size: headerSize,
    main_heading: headerText,
    header_type: headerType,
    header_url: headerUrl = '',
    image: { url: imageUrl } = {},
    link: { href: ctaHref = '', title: ctaText } = {},
    subheader_size: subheaderSize,
    sub_heading: subheaderText,
    text_overlay_background_color: textOverlayBackgroundColor,
  } = content;

  const parentId = `mediaCta_${id}`;

  const handleClickEvent = (e, linkId, linkText, url) => {
    e.stopPropagation?.();
    sendLinkClickEvent(parentId, linkId, linkText, url);
  };

  const onHeaderClick = (e) => {
    handleClickEvent(e, 'header', headerText, headerUrl);
  };

  const onCtaClick = (e) => {
    handleClickEvent(e, 'cta', ctaText, ctaHref);
  };

  const formattedTextLookup = Object.fromEntries(
    [headerText, subheaderText, ctaText, disclaimerText].map((text) => [
      text,
      text
        ?.split('\n')
        .map((txt, i) => (i === 0 ? [txt] : [<br key={txt} />, txt])),
    ])
  );

  const { HeaderTag, SubheaderTag } = (() => {
    switch (headerType) {
      case 'h1': {
        return { HeaderTag: 'h1', SubheaderTag: 'h2' };
      }
      case 'h3': {
        return { HeaderTag: 'h3', SubheaderTag: 'h4' };
      }
      default: {
        return { HeaderTag: 'h2', SubheaderTag: 'h3' };
      }
    }
  })();
  const isInternalLink = ctaHref.indexOf('/') === 0;
  const { headerSizeClassNames } = getMediaCtaSizeClassNames(headerSize);
  const { subheaderSizeClassNames } = getMediaCtaSizeClassNames(subheaderSize);
  const { ctaSizeClassNames } = getMediaCtaSizeClassNames(ctaSize);
  const textOverlayBgColorClassName = getOverlayBgColorClassName(
    textOverlayBackgroundColor
  );
  const disclaimerOverlayBgColorClassName = getOverlayBgColorClassName(
    disclaimerOverlayBackgroundColor
  );

  const disclaimerClassNames = (() => {
    switch (disclaimerAlignment) {
      case 'left': {
        return 'justify-content-start align-items-start text-start';
      }
      case 'right': {
        return 'justify-content-end align-items-end text-end';
      }
      default: {
        return 'justify-content-center align-items-center text-center';
      }
    }
  })();

  const applyPillStyleToCta = ctaType === 'pill';
  const applyButtonStyleToCta = ctaType === 'button';

  const { backgroundColor: ctaBackgroundColor, textColor: ctaTextColor } =
    getCtaTheme(ctaTheme);

  const headerColorClassName = styles[headerColor];
  const ctaColorClassName =
    applyPillStyleToCta || applyButtonStyleToCta
      ? ctaTextColor
      : styles[ctaColor];
  const disclaimerColorClassName = styles[disclaimerColor];

  return (
    <div
      className={classNames(
        'm-auto position-relative w-100 h-100',
        styles.grid_slide
      )}
    >
      <Image
        fill
        src={imageUrl}
        alt={headerText ?? subheaderText ?? ''}
        objectFit="cover"
        sizes="(min-width: 992px) 50vw, 100vw"
      />
      <div
        className={classNames(
          'pe-8 pe-md-40 position-absolute',
          styles.grid_slide_text,
          headerColor === 'navy' ? 'border-primary' : 'border-white'
        )}
      >
        <div
          className={classNames(
            'pt-3 pt-lg-5 border-2',
            styles.text_overlay_background,
            styles.header_subheader_decorator,
            headerColor === 'navy'
              ? styles.header_subheader_decorator_primary
              : styles.header_subheader_decorator_white,
            textOverlayBgColorClassName,
            ctaText && !applyPillStyleToCta && !applyButtonStyleToCta
              ? styles.overlay_no_bottom_left_radius
              : styles.overlay_radius
          )}
        >
          <Link
            href={headerUrl}
            className={classNames(headerColorClassName)}
            onClick={onHeaderClick}
          >
            {headerText && (
              <HeaderTag
                className={classNames(
                  'font-freight m-0 pb-3 px-3 px-lg-5 mb-lg-6',
                  headerSizeClassNames
                )}
              >
                {formattedTextLookup[headerText]}
              </HeaderTag>
            )}
            {subheaderText && (
              <SubheaderTag
                className={classNames(
                  'font-geo m-0 pb-3 px-3 px-lg-5 text-uppercase',
                  subheaderSizeClassNames
                )}
              >
                {formattedTextLookup[subheaderText]}
              </SubheaderTag>
            )}
          </Link>
        </div>
        {ctaText && (
          <div
            className={classNames(
              styles.text_overlay_background,
              styles.sub_overlay_bottom_radius,
              {
                [textOverlayBgColorClassName]:
                  !applyPillStyleToCta && !applyButtonStyleToCta,
                'mt-4': applyPillStyleToCta || applyButtonStyleToCta,
              }
            )}
          >
            <Link
              href={ctaHref}
              rel={isInternalLink ? '' : 'noopener noreferrer'}
              target={isInternalLink ? '' : '_blank'}
              className={classNames(ctaColorClassName, ctaSizeClassNames, {
                [styles.cta_decorator]:
                  !applyPillStyleToCta && !applyButtonStyleToCta,
                [`btn text-center ${ctaBackgroundColor}`]:
                  applyPillStyleToCta || applyButtonStyleToCta,
                'rounded-pill': applyPillStyleToCta,
              })}
              onClick={onCtaClick}
            >
              {ctaText}
            </Link>
          </div>
        )}
      </div>
      {disclaimerText && (
        <div className="d-flex w-100 fst-italic position-absolute bottom-0">
          <div
            className={classNames(
              'd-flex w-100 px-5 py-3 font-acumin fs-6 fs-md-7 fw-300',
              disclaimerClassNames
            )}
          >
            <div
              className={classNames(
                'px-3',
                disclaimerColorClassName,
                disclaimerOverlayBgColorClassName,
                styles.disclaimer_overlay,
                styles.overlay_radius
              )}
            >
              <span>{formattedTextLookup[disclaimerText]}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function getCtaTheme(ctaTheme) {
  switch (ctaTheme) {
    case 'white': {
      return {
        backgroundColor: 'bg-white',
        textColor: 'text-primary',
      };
    }
    case 'gold': {
      return {
        backgroundColor: 'bg-secondary',
        textColor: 'text-primary',
      };
    }
    case 'cream': {
      return {
        backgroundColor: 'bg-cream',
        textColor: 'text-primary',
      };
    }
    case 'red': {
      return {
        backgroundColor: 'bg-alert',
        textColor: 'text-white',
      };
    }
    default: {
      return {
        backgroundColor: 'bg-primary',
        textColor: 'text-white',
      };
    }
  }
}

function getMediaCtaSizeClassNames(textSize) {
  switch (textSize) {
    case 'large': {
      return {
        headerSizeClassNames:
          'fs-22 fs-sm-24 fs-md-28 fs-lg-24 fs-xl-28 fs-xxl-32',
        subheaderSizeClassNames:
          'fs-18 fs-sm-20 fs-md-24 fs-lg-20 fs-xl-24 fs-xxl-28',
        ctaSizeClassNames:
          'fs-12 fs-sm-14 fs-md-18 fs-lg-14 fs-xl-18 fs-xxl-22',
      };
    }
    case 'medium': {
      return {
        headerSizeClassNames:
          'fs-14 fs-sm-16 fs-md-20 fs-lg-16 fs-xl-20 fs-xxl-24',
        subheaderSizeClassNames:
          'fs-10 fs-sm-12 fs-md-16 fs-lg-12 fs-xl-16 fs-xxl-18',
        ctaSizeClassNames: 'fs-8 fs-md-12 fs-lg-8 fs-xl-12 fs-xxl-14',
      };
    }
    default: {
      return {
        headerSizeClassNames:
          'fs-10 fs-sm-14 fs-md-18 fs-lg-14 fs-xl-18 fs-xxl-20',
        subheaderSizeClassNames:
          'fs-7 fs-sm-12 fs-md-14 fs-lg-10 fs-xl-14 fs-xxl-15',
        ctaSizeClassNames: 'fs-6 fs-sm-7 fs-md-12 fs-lg-8 fs-xxl-9',
      };
    }
  }
}

function getOverlayBgColorClassName(overlayBackgroundColor) {
  switch (overlayBackgroundColor) {
    case 'white': {
      return styles.text_overlay_background_white;
    }
    case 'cream': {
      return styles.text_overlay_background_cream;
    }
    case 'navy': {
      return styles.text_overlay_background_navy;
    }
    case 'gold': {
      return styles.text_overlay_background_gold;
    }
    default: {
      return '';
    }
  }
}

export default GridCarouselItem;
