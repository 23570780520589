import classNames from 'classnames';
import { default as GlobalCarousel } from 'components/Carousel';
import MediaCTA from 'components/MediaCTA';
import styles from './Carousel.module.scss';
import CarouselItem from './CarouselItem';
import GridCarouselItem from './GridCarouselItem';

const Carousel = (props) => {
  const {
    id,
    indicator_color: indicatorColor = 'white',
    isGridCarousel = false,
    items,
  } = props;
  return (
    <GlobalCarousel
      indicatorColor={indicatorColor}
      isGridCarousel={isGridCarousel}
      className={classNames('position-relative', {
        'mb-16 mb-md-0': !isGridCarousel,
      })}
    >
      {items.map((item, index) => {
        const {
          _content_type_uid: contentTypeUid,
          uid,
          content = {},
        } = item || {};

        return (
          <GlobalCarousel.Item key={uid} interval={5000} wrap="true">
            {isGridCarousel && contentTypeUid === 'carousel_item' && (
              <GridCarouselItem content={content} id={id} index={index} />
            )}
            {isGridCarousel && contentTypeUid === 'media_cta' && (
              <MediaCTA
                customCss={styles.grid_slide}
                cmsData={[item]}
                id={id}
                index={index}
              />
            )}
            {!isGridCarousel && (
              <CarouselItem content={content} index={index} />
            )}
          </GlobalCarousel.Item>
        );
      })}
    </GlobalCarousel>
  );
};

export default Carousel;
