import Image from 'components/Image';
import Link from 'components/Link';
import styles from './CarouselItem.module.scss';

export function CarouselItem({ content, index }) {
  const {
    link: { href: linkHref = '', title: linkTitle } = {},
    image: { url: imageUrl } = {},
    mobile_image: { url: mobileImageUrl } = {},
    main_heading: heading,
    sub_heading: subHeading,
  } = content;

  const HeadingTag = index === 0 ? 'h1' : 'h2';
  const isInternalLink = linkHref.indexOf('/') === 0;

  return (
    <div className={`position-relative ${styles.slide_outer}`}>
      <div className={`position-absolute ${styles.slide_inner}`}>
        <div className={styles.slide}>
          <div className={`d-none d-sm-inline-block ${styles.big_image}`}>
            <Image
              fill
              alt={heading}
              className="d-sm-inline"
              objectFit="cover"
              priority={index === 0}
              role="presentation"
              sizes="(max-width: 992px) 992w, (max-width: 1200px) 1200w, (max-width: 1900px) 1900w, 100vw"
              src={imageUrl}
            />
          </div>
          <div className="d-inline-block d-sm-none">
            <Image
              fill
              alt={heading}
              objectFit="cover"
              priority={index === 0}
              role="presentation"
              sizes="768w"
              src={mobileImageUrl}
            />
          </div>
        </div>
      </div>
      <div className={`pe-8 pe-md-40 position-absolute ${styles.slide_text}`}>
        <HeadingTag className="font-freight fs-22 fs-xl-36 fw-900 lh-1 pb-4 pb-xl-0 text-white">
          {heading}
        </HeadingTag>
        <p
          className={`font-acumin fs-8 fs-xl-12 fw-400 lh-sm-1 mb-4 pb-8 pe-63 pe-sm-0 text-white ${styles.slide_paragraph}`}
        >
          {subHeading}
        </p>
        {isInternalLink ? (
          <Link href={linkHref} className="btn btn-secondary fs-7 px-16">
            {linkTitle}
          </Link>
        ) : (
          <Link
            href={linkHref}
            rel="noopener noreferrer"
            target="_blank"
            className="btn btn-secondary fs-7 px-16"
          >
            {linkTitle}
          </Link>
        )}
      </div>
    </div>
  );
}

export default CarouselItem;
