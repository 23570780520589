import React from 'react';
import classNames from 'classnames';
import useIsSmallViewport from 'hooks/useIsSmallViewport';
import styles from './Video.module.scss';

const Video = ({
  autoPlay,
  controls,
  loop,
  muted,
  playsInline,
  desktopSrc,
  mobileSrc,
  customCss,
}) => {
  const isSmallViewport = useIsSmallViewport();

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video
        autoPlay={autoPlay}
        className={classNames(customCss, styles.video)}
        controls={controls}
        loop={loop}
        muted={muted}
        playsInline={playsInline}
        preload="metadata"
        src={isSmallViewport ? mobileSrc : desktopSrc}
      >
        Sorry, this browser does not support embedded videos.
      </video>
    </>
  );
};

export default Video;
