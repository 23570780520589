const NAVY = 'navy';
const WHITE = 'white';
const GOLD = 'gold';
const CREAM = 'cream';
const RED = 'red';

/**
 * @param {NAVY|WHITE|GOLD|CREAM|RED} inputColor
 * @example getStyleColor('navy'); returns 'primary'
 * @default 'white'
 * @returns string
 */
export default function getStyleColor(inputColor) {
  switch (inputColor) {
    case NAVY: {
      return 'primary';
    }
    case GOLD: {
      return 'secondary';
    }
    case CREAM: {
      return 'cream';
    }
    case RED: {
      return 'alert';
    }
    /* eslint-disable-next-line unicorn/no-useless-switch-case */
    case WHITE:
    default: {
      return 'white';
    }
  }
}
