import React from 'react';
import PropTypes from 'prop-types';

const MobileClose = ({ testId }) => (
  <svg
    data-testid={testId}
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0162 21.339C17.1004 21.339 22.0325 16.5621 22.0325 10.6695C22.0325 4.77689 17.1004 0 11.0162 0C4.93214 0 0 4.77689 0 10.6695C0 16.5621 4.93214 21.339 11.0162 21.339Z"
      fill="#1B1344"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3141 7.50465C16.7058 7.11522 16.7058 6.48384 16.3141 6.09442C15.9225 5.705 15.2876 5.705 14.896 6.09442L11.1966 9.77295L7.53716 6.09279C7.14774 5.70117 6.51636 5.70117 6.12694 6.09279C5.73752 6.48442 5.73752 7.11936 6.12694 7.51099L9.77846 11.1831L6.06611 14.8746C5.67448 15.2641 5.67448 15.8954 6.06611 16.2849C6.45773 16.6743 7.09268 16.6743 7.4843 16.2849L11.1887 12.6013L14.9072 16.3408C15.2966 16.7325 15.928 16.7325 16.3174 16.3408C16.7068 15.9492 16.7068 15.3143 16.3174 14.9226L12.6068 11.1911L16.3141 7.50465Z"
      fill="white"
    />
  </svg>
);

MobileClose.propTypes = {
  testId: PropTypes.string,
};

export default MobileClose;
