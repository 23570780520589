import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCommittedRef from '@restart/hooks/useCommittedRef';
import useEventCallback from '@restart/hooks/useEventCallback';
import useTimeout from '@restart/hooks/useTimeout';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import Anchor from '@restart/ui/Anchor';
import classNames from 'classnames';
import CarouselCaption from 'react-bootstrap/CarouselCaption';
import CarouselItem from 'react-bootstrap/CarouselItem';
import { map, forEach } from 'react-bootstrap/ElementChildren';
import { useBootstrapPrefix, useIsRTL } from 'react-bootstrap/ThemeProvider';
import TransitionWrapper from 'react-bootstrap/TransitionWrapper';
import transitionEndListener from 'react-bootstrap/transitionEndListener';
import triggerBrowserReflow from 'react-bootstrap/triggerBrowserReflow';
import { useUncontrolled } from 'uncontrollable';
import styles from './Carousel.module.scss';

const SWIPE_THRESHOLD = 40;

const defaultProps = {
  slide: true,
  fade: false,
  controls: true,
  indicators: true,
  indicatorLabels: [],
  defaultActiveIndex: 0,
  interval: 5000,
  keyboard: true,
  pause: false,
  wrap: true,
  touch: true,

  prevIcon: <span aria-hidden="true" className="carousel-control-prev-icon" />,
  prevLabel: 'Previous',

  nextIcon: <span aria-hidden="true" className="carousel-control-next-icon" />,
  nextLabel: 'Next',
};

function isVisible(element) {
  if (
    !element ||
    !element.style ||
    !element.parentNode ||
    !element.parentNode.style
  ) {
    return false;
  }

  const elementStyle = getComputedStyle(element);

  return (
    elementStyle.display !== 'none' &&
    elementStyle.visibility !== 'hidden' &&
    getComputedStyle(element.parentNode).display !== 'none'
  );
}

const Carousel = React.forwardRef((uncontrolledProps, ref) => {
  const {
    // Need to define the default "as" during prop destructuring to be compatible with styled-components github.com/react-bootstrap/react-bootstrap/issues/3595
    as: Component = 'div',
    bsPrefix,
    slide,
    fade,
    controls,
    indicators,
    indicatorLabels,
    activeIndex,
    onSelect,
    onSlide,
    onSlid,
    interval,
    keyboard,
    onKeyDown,
    pause,
    onMouseOver,
    onMouseOut,
    wrap,
    touch,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    prevIcon,
    prevLabel,
    nextIcon,
    nextLabel,
    variant,
    className,
    children,
    indicatorColor = 'white',
    isGridCarousel = false,
    ...props
  } = useUncontrolled(uncontrolledProps, {
    activeIndex: 'onSelect',
  });

  const prefix = useBootstrapPrefix(bsPrefix, 'carousel');

  const isRTL = useIsRTL();

  const nextDirectionRef = useRef(null);
  const [direction, setDirection] = useState('next');
  const [paused, setPaused] = useState(false);
  const [isSliding, setIsSliding] = useState(false);
  const [renderedActiveIndex, setRenderedActiveIndex] = useState(
    activeIndex || 0
  );

  useEffect(() => {
    if (!isSliding && activeIndex !== renderedActiveIndex) {
      if (nextDirectionRef.current) {
        setDirection(nextDirectionRef.current);
      } else {
        setDirection(
          (activeIndex || 0) > renderedActiveIndex ? 'next' : 'prev'
        );
      }

      if (slide) {
        setIsSliding(true);
      }

      setRenderedActiveIndex(activeIndex || 0);
    }
  }, [activeIndex, isSliding, renderedActiveIndex, slide]);

  useEffect(() => {
    if (nextDirectionRef.current) {
      nextDirectionRef.current = null;
    }
  });

  let numChildren = 0;
  let activeChildInterval;

  // Iterate to grab all of the children's interval values
  // (and count them, too)
  forEach(children, (child, index) => {
    ++numChildren;
    if (index === activeIndex) {
      activeChildInterval = child.props.interval;
    }
  });

  const activeChildIntervalRef = useCommittedRef(activeChildInterval);

  const prev = useCallback(
    (event) => {
      if (isSliding) {
        return;
      }

      let nextActiveIndex = renderedActiveIndex - 1;
      if (nextActiveIndex < 0) {
        if (!wrap) {
          return;
        }

        nextActiveIndex = numChildren - 1;
      }

      nextDirectionRef.current = 'prev';
      onSelect?.(nextActiveIndex, event);
    },
    [isSliding, renderedActiveIndex, onSelect, wrap, numChildren]
  );

  // This is used in the setInterval, so it should not invalidate.
  const next = useEventCallback((event) => {
    if (isSliding) {
      return;
    }

    let nextActiveIndex = renderedActiveIndex + 1;
    if (nextActiveIndex >= numChildren) {
      if (!wrap) {
        return;
      }

      nextActiveIndex = 0;
    }

    nextDirectionRef.current = 'next';

    onSelect?.(nextActiveIndex, event);
  });

  const elementRef = useRef();

  useImperativeHandle(ref, () => ({
    element: elementRef.current,
    prev,
    next,
  }));

  // This is used in the setInterval, so it should not invalidate.
  const nextWhenVisible = useEventCallback(() => {
    if (!document.hidden && isVisible(elementRef.current)) {
      if (isRTL) {
        prev();
      } else {
        next();
      }
    }
  });

  const slideDirection = direction === 'next' ? 'start' : 'end';

  useUpdateEffect(() => {
    if (slide) {
      // These callbacks will be handled by the <Transition> callbacks.
      return;
    }

    onSlide?.(renderedActiveIndex, slideDirection);
    onSlid?.(renderedActiveIndex, slideDirection);
  }, [renderedActiveIndex]);

  const orderClassName = `${prefix}-item-${direction}`;
  const directionalClassName = `${prefix}-item-${slideDirection}`;

  const handleEnter = useCallback(
    (node) => {
      triggerBrowserReflow(node);

      onSlide?.(renderedActiveIndex, slideDirection);
    },
    [onSlide, renderedActiveIndex, slideDirection]
  );

  const handleEntered = useCallback(() => {
    setIsSliding(false);

    onSlid?.(renderedActiveIndex, slideDirection);
  }, [onSlid, renderedActiveIndex, slideDirection]);

  const handleKeyDown = useCallback(
    (event) => {
      if (keyboard && !/input|textarea/i.test(event.target.tagName)) {
        switch (event.key) {
          case 'ArrowLeft': {
            event.preventDefault();
            if (isRTL) {
              next(event);
            } else {
              prev(event);
            }
            return;
          }
          case 'ArrowRight': {
            event.preventDefault();
            if (isRTL) {
              prev(event);
            } else {
              next(event);
            }
            return;
          }
          default:
        }
      }

      onKeyDown?.(event);
    },
    [keyboard, onKeyDown, prev, next, isRTL]
  );

  const handleMouseOver = useCallback(
    (event) => {
      if (pause === 'hover') {
        setPaused(true);
      }

      onMouseOver?.(event);
    },
    [pause, onMouseOver]
  );

  const handleMouseOut = useCallback(
    (event) => {
      if (pause === 'hover') {
        setPaused(false);
      }
      onMouseOut?.(event);
    },
    [pause, onMouseOut]
  );

  const touchStartXRef = useRef(0);
  const touchDeltaXRef = useRef(0);
  const touchUnpauseTimeout = useTimeout();

  const handleTouchStart = useCallback(
    (event) => {
      touchStartXRef.current = event.touches[0].clientX;
      touchDeltaXRef.current = 0;

      if (pause === 'hover') {
        setPaused(true);
      }

      onTouchStart?.(event);
    },
    [pause, onTouchStart]
  );

  const handleTouchMove = useCallback(
    (event) => {
      touchDeltaXRef.current =
        event.touches && event.touches.length > 1
          ? 0
          : event.touches[0].clientX - touchStartXRef.current;

      onTouchMove?.(event);
    },
    [onTouchMove]
  );

  const handleTouchEnd = useCallback(
    (event) => {
      if (touch) {
        const touchDeltaX = touchDeltaXRef.current;

        if (Math.abs(touchDeltaX) > SWIPE_THRESHOLD) {
          if (touchDeltaX > 0) {
            prev(event);
          } else {
            next(event);
          }
        }
      }

      if (pause === 'hover') {
        touchUnpauseTimeout.set(() => {
          setPaused(false);
        }, interval || undefined);
      }

      onTouchEnd?.(event);
    },
    [touch, pause, prev, next, touchUnpauseTimeout, interval, onTouchEnd]
  );

  const shouldPlay = interval !== null && !paused && !isSliding;

  const intervalHandleRef = useRef();

  useEffect(() => {
    if (!shouldPlay) {
      return;
    }

    const nextFunc = isRTL ? prev : next;
    intervalHandleRef.current = window.setInterval(
      document.visibilityState ? nextWhenVisible : nextFunc,
      activeChildIntervalRef.current ?? interval ?? undefined
    );

    return () => {
      if (intervalHandleRef.current !== null) {
        clearInterval(intervalHandleRef.current);
      }
    };
  }, [
    shouldPlay,
    prev,
    next,
    activeChildIntervalRef,
    interval,
    nextWhenVisible,
    isRTL,
  ]);

  const indicatorOnClicks = useMemo(
    () =>
      indicators &&
      Array.from({ length: numChildren }, (_, index) => (event) => {
        onSelect?.(index, event);
      }),
    [indicators, numChildren, onSelect]
  );

  return (
    <Component
      ref={elementRef}
      {...props}
      className={classNames(
        'carousel',
        className,
        prefix,
        slide && 'slide',
        fade && `${prefix}-fade`,
        variant && `${prefix}-${variant}`
      )}
      onKeyDown={handleKeyDown}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {indicators && (
        <ul
          className={classNames(`list-unstyled ${prefix}-indicators`, {
            [styles.grid_carousel_indicators]: isGridCarousel,
            [styles[`${indicatorColor}_indicators`]]: isGridCarousel,
          })}
        >
          {map(children, (_, index) => (
            <li>
              <button
                key={index}
                type="button"
                data-bs-target="" // Bootstrap requires this in their css.
                aria-label={
                  indicatorLabels?.length
                    ? indicatorLabels[index]
                    : `Slide ${index + 1} of ${children.length}`
                }
                className={classNames('carousel-indicator', {
                  active: index === renderedActiveIndex,
                  [styles.grid_carousel_indicator]: isGridCarousel,
                  [styles.grid_carousel_indicator_active]:
                    isGridCarousel && index === renderedActiveIndex,
                })}
                aria-current={index === renderedActiveIndex}
                onClick={
                  indicatorOnClicks ? indicatorOnClicks[index] : undefined
                }
              />
            </li>
          ))}
          <li>
            <button
              type="button"
              data-bs-target=""
              aria-label={paused ? 'Play slides' : 'Pause Slides'}
              className="indicator-play pb-2"
              onClick={() => setPaused(!paused)}
            >
              {paused ? (
                <FontAwesomeIcon className="mh-100 mw-100" icon={faPlay} />
              ) : (
                <FontAwesomeIcon className="mh-100 mw-100" icon={faPause} />
              )}
            </button>
          </li>
        </ul>
      )}

      <ul className={`list-unstyled m-0 ${prefix}-inner`}>
        {map(children, (child, index) => {
          const isActive = index === renderedActiveIndex;

          return slide ? (
            <TransitionWrapper
              in={isActive}
              addEndListener={transitionEndListener}
              onEnter={isActive ? handleEnter : undefined}
              onEntered={isActive ? handleEntered : undefined}
            >
              {(status, innerProps) => (
                <li>
                  {React.cloneElement(child, {
                    ...innerProps,
                    className: classNames(
                      child.props.className,
                      isActive && status !== 'entered' && orderClassName,
                      (status === 'entered' || status === 'exiting') &&
                        'active',
                      (status === 'entering' || status === 'exiting') &&
                        directionalClassName
                    ),
                  })}
                </li>
              )}
            </TransitionWrapper>
          ) : (
            <li>
              {React.cloneElement(child, {
                className: classNames(
                  child.props.className,
                  isActive && 'active'
                ),
              })}
            </li>
          );
        })}
      </ul>

      {controls && (
        <>
          {(wrap || activeIndex !== 0) && (
            <Anchor className={`${prefix}-control-prev`} onClick={prev}>
              {prevIcon}
              {prevLabel && (
                <span className="visually-hidden">{prevLabel}</span>
              )}
            </Anchor>
          )}
          {(wrap || activeIndex !== numChildren - 1) && (
            <Anchor className={`${prefix}-control-next`} onClick={next}>
              {nextIcon}
              {nextLabel && (
                <span className="visually-hidden">{nextLabel}</span>
              )}
            </Anchor>
          )}
        </>
      )}
    </Component>
  );
});

Carousel.displayName = 'Carousel';
Carousel.defaultProps = defaultProps;

export default Object.assign(Carousel, {
  Caption: CarouselCaption,
  Item: CarouselItem,
});
