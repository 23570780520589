/* eslint-disable complexity */
import { memo, useState, useRef } from 'react';
import { useRouter } from 'next/router';
import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Modal from 'react-bootstrap/Modal';
import Image from 'components/Image';
import Link from 'components/Link';
import Video from 'components/Video';
import CloseIcon from 'svg/CloseIcon/index.jsx';
import getStyleColor from 'utils/get-style-color';
import { sendLinkClickEvent } from 'utils/metrics';
import styles from './MediaCTA.module.scss';

function MediaCTA({ id, cmsData, customCss, index, priority, sizes, ...rest }) {
  const router = useRouter();
  const [modalIsOpen, setModalIsOpen] = useState(null);
  const [videoSrc, setVideoSrc] = useState(null);
  const clickBorderRef = useRef(null);

  if (!cmsData?.length) {
    return null;
  }

  const {
    autoplay_video: autoplayVideo,
    background_media: backgroundMedia,
    background_media_type: backgroundMediaType,
    disclaimer_alignment: disclaimerAlignment,
    disclaimer_color: disclaimerColor,
    disclaimer_multi_line_text: disclaimerMultiLineText,
    disclaimer_overlay_background_color: disclaimerOverlayBackgroundColor,
    disclaimer_text: disclaimerText,
    first_cta_color: firstCtaColor,
    first_cta_size: firstCtaSize,
    first_cta_text: firstCtaText,
    first_cta_url: firstCtaUrl,
    first_cta_type: firstCtaType,
    first_cta_theme: firstCtaTheme,
    full_video: fullVideo,
    header_color: headerColor,
    header_size: headerSize,
    header_text: headerText,
    header_type: headerType,
    header_url: headerUrl,
    play_button_color: playButtonColor,
    play_video_link_text: playVideoLinkText,
    second_cta_color: secondCtaColor,
    second_cta_size: secondCtaSize,
    second_cta_text: secondCtaText,
    second_cta_url: secondCtaUrl,
    second_cta_type: secondCtaType,
    second_cta_theme: secondCtaTheme,
    show_play_button: showPlayButton,
    subheader_size: subheaderSize,
    subheader_text: subheaderText,
    text_alignment: textAlignment,
    text_position: textPosition,
    text_overlay_background_color: textOverlayBackgroundColor,
  } = cmsData[0];

  const isVideo = backgroundMediaType === 'video';
  const parentId = `mediaCta_${id}`;

  const handleClickEvent = (e, linkId, linkText, url) => {
    e.stopPropagation?.();
    sendLinkClickEvent(parentId, linkId, linkText, url);
  };

  const onTileBtnClick = (e) => {
    if ((headerUrl ?? '') === '') {
      return;
    }
    e.preventDefault?.();
    clickBorderRef?.current?.classList.add(styles.button_content_clicked);
    handleClickEvent(e, 'tile', '', headerUrl);

    if (headerUrl) {
      router.push(headerUrl);
    }
  };

  const onHeaderClick = (e) => {
    handleClickEvent(e, 'header', headerText, headerUrl);
  };

  const onFirstCtaClick = (e) => {
    handleClickEvent(e, 'firstCta', firstCtaText, firstCtaUrl);
  };

  const onSecondCtaClick = (e) => {
    handleClickEvent(e, 'secondCta', secondCtaText, secondCtaUrl);
  };

  const onPlayVideoClick = (e) => {
    e.preventDefault?.();
    const src = fullVideo?.url ?? backgroundMedia?.url;
    setVideoSrc(src);
    setModalIsOpen(true);
    handleClickEvent(e, 'video', '', src);
  };

  const hideVideo = () => {
    setModalIsOpen(null);
    setVideoSrc(null);
  };

  const formattedTextLookup = Object.fromEntries(
    [
      headerText,
      subheaderText,
      firstCtaText,
      secondCtaText,
      disclaimerMultiLineText,
    ].map((text) => [
      text,
      text
        ?.split('\n')
        .map((txt, i) => (i === 0 ? [txt] : [<br key={txt} />, txt])),
    ])
  );

  const { buttonTextAlignStyle, alignItemsClassName, isTextCentered } = (() => {
    switch (textAlignment) {
      case 'center': {
        return {
          buttonTextAlignStyle: styles.button_container_center_align,
          isTextCentered: true,
          alignItemsClassName: 'align-items-center',
        };
      }
      case 'right': {
        return {
          buttonTextAlignStyle: styles.button_container_right_align,
          isTextCentered: false,
          alignItemsClassName: 'align-items-end',
        };
      }
      default: {
        return {
          buttonTextAlignStyle: styles.button_container_left_align,
          isTextCentered: false,
          alignItemsClassName: 'align-items-start',
        };
      }
    }
  })();

  const justifyContentClassName = (() => {
    switch (textPosition) {
      case 'center': {
        return 'justify-content-center';
      }
      case 'bottom': {
        return 'justify-content-end';
      }
      default: {
        return 'justify-content-start';
      }
    }
  })();

  const { HeaderTag, SubheaderTag } = (() => {
    switch (headerType) {
      case 'h1': {
        return { HeaderTag: 'h1', SubheaderTag: 'h2' };
      }
      case 'h3': {
        return { HeaderTag: 'h3', SubheaderTag: 'h4' };
      }
      default: {
        return { HeaderTag: 'h2', SubheaderTag: 'h3' };
      }
    }
  })();

  const { headerSizeClassNames } = getMediaCtaSizeClassNames(headerSize);
  const { subheaderSizeClassNames } = getMediaCtaSizeClassNames(subheaderSize);
  const { ctaSizeClassNames: firstCtaSizeClassNames } =
    getMediaCtaSizeClassNames(firstCtaSize);
  const { ctaSizeClassNames: secondCtaSizeClassNames } =
    getMediaCtaSizeClassNames(secondCtaSize);

  const disclaimerClassNames = (() => {
    switch (disclaimerAlignment) {
      case 'left': {
        return 'justify-content-start align-items-start text-start';
      }
      case 'right': {
        return 'justify-content-end align-items-end text-end';
      }
      default: {
        return 'justify-content-center align-items-center text-center';
      }
    }
  })();

  const applyPillStyleToFirstCta = firstCtaType === 'pill';
  const applyPillStyleToSecondCta = secondCtaType === 'pill';
  const textOverlayBgColorClassName = getOverlayBgColorClassName(
    textOverlayBackgroundColor
  );
  const disclaimerOverlayBgColorClassName = getOverlayBgColorClassName(
    disclaimerOverlayBackgroundColor
  );

  const {
    backgroundColor: firstCtaBackgroundColor,
    textColor: firstCtaTextColor,
  } = getCtaTheme(firstCtaTheme);

  const {
    backgroundColor: secondCtaBackgroundColor,
    textColor: secondCtaTextColor,
  } = getCtaTheme(secondCtaTheme);

  const headerColorClassName = styles[headerColor];
  const firstCtaColorClassName = applyPillStyleToFirstCta
    ? firstCtaTextColor
    : styles[firstCtaColor];
  const secondCtaColorClassName = applyPillStyleToSecondCta
    ? secondCtaTextColor
    : styles[secondCtaColor];
  const disclaimerColorClassName = styles[disclaimerColor];
  const isDarkBackground =
    getStyleColor(textOverlayBackgroundColor) === 'primary';
  const playButtonColorClass =
    styles[`play_button_${playButtonColor ?? 'navy'}`];

  const renderHeaderSubheaderSection = () => {
    return (
      <>
        {headerText && (
          <HeaderTag
            className={classNames(
              'font-freight m-0 pb-3 px-3 px-lg-5 mb-lg-6',
              headerSizeClassNames
            )}
          >
            {formattedTextLookup[headerText]}
          </HeaderTag>
        )}
        {subheaderText && (
          <SubheaderTag
            className={classNames(
              'font-geo m-0 pb-3 px-3 px-lg-5 text-uppercase',
              subheaderSizeClassNames
            )}
          >
            {formattedTextLookup[subheaderText]}
          </SubheaderTag>
        )}
      </>
    );
  };

  return (
    <div
      id={`mediaCTA_${id}`}
      className={classNames('m-auto position-relative w-100 h-100', customCss)}
    >
      {isVideo && autoplayVideo ? (
        <div className={styles.video_container}>
          <Video
            autoPlay
            loop
            muted
            playsInline
            desktopSrc={backgroundMedia?.url ?? fullVideo?.url}
            mobileSrc={backgroundMedia?.url ?? fullVideo?.url}
            customCss="mh-100"
          />
        </div>
      ) : (
        <Image
          fill
          priority={priority}
          src={backgroundMedia?.url}
          alt={
            headerText ?? subheaderText ?? firstCtaText ?? secondCtaText ?? ''
          }
          objectFit="cover"
          sizes={sizes}
          {...rest}
        />
      )}
      <div className={`position-absolute h-100 w-100 ${styles.overlay}`}>
        <button
          type="button"
          className={classNames(
            `m-0 p-0 border-0 bg-transparent h-100 w-100`,
            buttonTextAlignStyle,
            {
              [styles.default_cursor]: (headerUrl ?? '') === '',
            }
          )}
          onClick={onTileBtnClick}
        >
          <div
            ref={clickBorderRef}
            className={classNames(
              'd-flex flex-column',
              alignItemsClassName,
              justifyContentClassName,
              buttonTextAlignStyle,
              headerColor === 'navy' ? 'border-primary' : 'border-white',
              styles.button_content
            )}
          >
            <div
              className={classNames(
                'pt-3 pt-lg-5 border-2',
                styles.text_overlay_background,
                (headerUrl ?? '') !== '' && styles.header_subheader_decorator,
                headerColor === 'navy'
                  ? styles.header_subheader_decorator_primary
                  : styles.header_subheader_decorator_white,
                textOverlayBgColorClassName,
                (firstCtaText || secondCtaText || playVideoLinkText) &&
                  textAlignment !== 'center' &&
                  !applyPillStyleToFirstCta &&
                  !applyPillStyleToSecondCta
                  ? textAlignment === 'right'
                    ? styles.overlay_no_bottom_right_radius
                    : styles.overlay_no_bottom_left_radius
                  : styles.overlay_radius
              )}
            >
              {(headerUrl ?? '') === '' ? (
                <div className={classNames(headerColorClassName)}>
                  {renderHeaderSubheaderSection()}
                </div>
              ) : (
                <Link
                  href={headerUrl}
                  className={classNames(headerColorClassName)}
                  onClick={onHeaderClick}
                >
                  {renderHeaderSubheaderSection()}
                </Link>
              )}
            </div>
            <div
              className={classNames(
                styles.text_overlay_background,
                styles.sub_overlay_bottom_radius,
                {
                  [textOverlayBgColorClassName]:
                    !applyPillStyleToFirstCta && !applyPillStyleToSecondCta,
                  'mt-4': applyPillStyleToFirstCta || applyPillStyleToSecondCta,
                }
              )}
            >
              {firstCtaText || secondCtaText ? (
                <div
                  className={classNames(
                    isTextCentered && secondCtaText && styles.horizontal_ctas,
                    { 'd-flex flex-row': isTextCentered && secondCtaText }
                  )}
                >
                  {firstCtaText && (
                    <div
                      className={classNames({
                        [`rounded-pill text-center ${firstCtaBackgroundColor}`]:
                          applyPillStyleToFirstCta,
                        'mb-2':
                          secondCtaText &&
                          applyPillStyleToFirstCta &&
                          !isTextCentered,
                        'me-2':
                          secondCtaText &&
                          applyPillStyleToFirstCta &&
                          isTextCentered,
                      })}
                    >
                      <Link
                        href={firstCtaUrl}
                        className={classNames(firstCtaColorClassName, {
                          [styles.cta_decorator]: !applyPillStyleToFirstCta,
                        })}
                        onClick={onFirstCtaClick}
                      >
                        <p
                          className={classNames(
                            'm-0 pb-2',
                            firstCtaSizeClassNames,
                            {
                              'px-3 px-lg-5': !applyPillStyleToFirstCta,
                              'px-5 px-lg-7': applyPillStyleToFirstCta,
                            }
                          )}
                        >
                          {formattedTextLookup[firstCtaText]}
                        </p>
                      </Link>
                    </div>
                  )}
                  {secondCtaText && (
                    <div
                      className={classNames({
                        [`rounded-pill text-center ${secondCtaBackgroundColor}`]:
                          applyPillStyleToSecondCta,
                        'mt-2':
                          firstCtaText &&
                          applyPillStyleToSecondCta &&
                          !isTextCentered,
                        'ms-2':
                          firstCtaText &&
                          applyPillStyleToSecondCta &&
                          isTextCentered,
                      })}
                    >
                      <Link
                        href={secondCtaUrl}
                        className={classNames(secondCtaColorClassName, {
                          [styles.cta_decorator]: !applyPillStyleToSecondCta,
                        })}
                        onClick={onSecondCtaClick}
                      >
                        <p
                          className={classNames(
                            'm-0 pb-2',
                            secondCtaSizeClassNames,
                            {
                              'px-3 px-lg-5': !applyPillStyleToSecondCta,
                              'px-5 px-lg-7': applyPillStyleToSecondCta,
                            }
                          )}
                        >
                          {formattedTextLookup[secondCtaText]}
                        </p>
                      </Link>
                    </div>
                  )}
                </div>
              ) : null}
              {playVideoLinkText && (
                <Link
                  href="#"
                  className={classNames(headerColorClassName)}
                  onClick={onPlayVideoClick}
                >
                  <p
                    className={classNames(
                      'font-freight m-0 px-3 px-lg-5',
                      subheaderSizeClassNames
                    )}
                  >
                    {playVideoLinkText}
                    <FontAwesomeIcon
                      icon={faPlay}
                      className={classNames(styles.play_icon)}
                    />
                  </p>
                </Link>
              )}
            </div>
          </div>
          {(disclaimerText || disclaimerMultiLineText) && (
            <div className="d-flex w-100 fst-italic position-absolute bottom-0">
              <div
                className={classNames(
                  'd-flex w-100 px-5 py-3 font-acumin fs-6 fs-md-7 fw-300',
                  disclaimerClassNames
                )}
              >
                <div
                  className={classNames(
                    'px-3',
                    disclaimerColorClassName,
                    disclaimerOverlayBgColorClassName,
                    styles.disclaimer_overlay,
                    styles.overlay_radius
                  )}
                >
                  <span>
                    {disclaimerMultiLineText
                      ? formattedTextLookup[disclaimerMultiLineText]
                      : disclaimerText}
                  </span>
                </div>
              </div>
            </div>
          )}
        </button>
      </div>
      {showPlayButton && (
        <div className={classNames(styles.play_button_container)}>
          <button
            type="button"
            className={classNames(
              'position-relative btn p-6 px-20',
              playButtonColorClass,
              {
                [styles.play_button_dark]:
                  !playButtonColor && !isDarkBackground,
                [styles.play_button_light]:
                  !playButtonColor && isDarkBackground,
              }
            )}
            onClick={onPlayVideoClick}
          >
            <FontAwesomeIcon fixedWidth icon={faCirclePlay} />
          </button>
        </div>
      )}
      <Modal
        centered
        size="lg"
        className={styles.modal}
        show={modalIsOpen}
        onHide={hideVideo}
      >
        <Modal.Header className="d-flex bg-transparent justify-content-end align-items-end p-0">
          <button
            type="button"
            className="btn btn-transparent text-white p-2"
            onClick={hideVideo}
          >
            <CloseIcon />
          </button>
        </Modal.Header>
        <Modal.Body className="p-0 w-100 bg-transparent">
          <Video autoPlay controls desktopSrc={videoSrc} mobileSrc={videoSrc} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

function getMediaCtaSizeClassNames(textSize) {
  switch (textSize) {
    case 'large': {
      return {
        headerSizeClassNames:
          'fs-22 fs-sm-24 fs-md-28 fs-lg-24 fs-xl-28 fs-xxl-32',
        subheaderSizeClassNames:
          'fs-18 fs-sm-20 fs-md-24 fs-lg-20 fs-xl-24 fs-xxl-28',
        ctaSizeClassNames:
          'fs-12 fs-sm-14 fs-md-18 fs-lg-14 fs-xl-18 fs-xxl-22',
      };
    }
    case 'medium': {
      return {
        headerSizeClassNames:
          'fs-14 fs-sm-16 fs-md-20 fs-lg-16 fs-xl-20 fs-xxl-24',
        subheaderSizeClassNames:
          'fs-10 fs-sm-12 fs-md-16 fs-lg-12 fs-xl-16 fs-xxl-18',
        ctaSizeClassNames: 'fs-8 fs-md-12 fs-lg-8 fs-xl-12 fs-xxl-14',
      };
    }
    default: {
      return {
        headerSizeClassNames:
          'fs-10 fs-sm-14 fs-md-18 fs-lg-14 fs-xl-18 fs-xxl-20',
        subheaderSizeClassNames:
          'fs-7 fs-sm-12 fs-md-14 fs-lg-10 fs-xl-14 fs-xxl-15',
        ctaSizeClassNames: 'fs-6 fs-sm-7 fs-md-12 fs-lg-8 fs-xxl-9',
      };
    }
  }
}

function getCtaTheme(ctaTheme) {
  switch (ctaTheme) {
    case 'white': {
      return {
        backgroundColor: 'bg-white',
        textColor: 'text-primary',
      };
    }
    case 'gold': {
      return {
        backgroundColor: 'bg-secondary',
        textColor: 'text-primary',
      };
    }
    case 'cream': {
      return {
        backgroundColor: 'bg-cream',
        textColor: 'text-primary',
      };
    }
    case 'red': {
      return {
        backgroundColor: 'bg-alert',
        textColor: 'text-white',
      };
    }
    default: {
      return {
        backgroundColor: 'bg-primary',
        textColor: 'text-white',
      };
    }
  }
}

function getOverlayBgColorClassName(overlayBackgroundColor) {
  switch (overlayBackgroundColor) {
    case 'white': {
      return styles.text_overlay_background_white;
    }
    case 'cream': {
      return styles.text_overlay_background_cream;
    }
    case 'navy': {
      return styles.text_overlay_background_navy;
    }
    case 'gold': {
      return styles.text_overlay_background_gold;
    }
    default: {
      return '';
    }
  }
}

export default memo(MediaCTA);
